/* eslint-disable prettier/prettier */ export const mapConfig = {
  // apiGoogle: 'AIzaSyApnMyiLH7ancoEOoFyrLYdob4sFWjhves',
  apiGoogle: process.env.REACT_APP_OTHERS_API_KEY,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const greyMapStyle = [
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d6e2e6'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#cfd4d5'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7492a8'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: 25
      }
    ]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dde2e3'
      }
    ]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#cfd4d5'
      }
    ]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dde2e3'
      }
    ]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7492a8'
      }
    ]
  },
  {
    featureType: 'landscape.natural.terrain',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dde2e3'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#588ca4'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#a9de83'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#bae6a1'
      }
    ]
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c6e8b3'
      }
    ]
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#bae6a1'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -45
      },
      {
        lightness: 10
      },
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#41626b'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c1d1d6'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#a6b5bb'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#9fb6bd'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -70
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#b4cbd4'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#588ca4'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#008cb5'
      }
    ]
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: -5
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#a6cbe3'
      }
    ]
  }
];

export const RouteData = [
  {
    userId: 11022,
    latitude: 11.033,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:29.875+05:30',
    batteryPercent: 56,
    bearing: 355.399,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.033,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:34.877+05:30',
    batteryPercent: 56,
    bearing: 355.399,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.034,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:39.866+05:30',
    batteryPercent: 56,
    bearing: 355.399,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.035,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:44.925+05:30',
    batteryPercent: 56,
    bearing: 355.399,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.035,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:49.893+05:30',
    batteryPercent: 56,
    bearing: 355.399,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.036,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:54.873+05:30',
    batteryPercent: 56,
    bearing: 351.057,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.036,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:55:59.843+05:30',
    batteryPercent: 56,
    bearing: 351.057,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.037,
    longitude: 77.038,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:04.848+05:30',
    batteryPercent: 56,
    bearing: 351.057,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.038,
    longitude: 77.037,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:09.855+05:30',
    batteryPercent: 56,
    bearing: 351.057,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.038,
    longitude: 77.037,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:14.845+05:30',
    batteryPercent: 56,
    bearing: 232.259,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.037,
    longitude: 77.037,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:19.9+05:30',
    batteryPercent: 56,
    bearing: 232.04,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.037,
    longitude: 77.036,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:24.871+05:30',
    batteryPercent: 56,
    bearing: 232.04,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.036,
    longitude: 77.036,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:29.878+05:30',
    batteryPercent: 56,
    bearing: 232.04,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.036,
    longitude: 77.035,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:34.881+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.036,
    longitude: 77.035,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:39.905+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.035,
    longitude: 77.034,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:44.886+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.035,
    longitude: 77.034,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:49.861+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.034,
    longitude: 77.033,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:54.86+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.034,
    longitude: 77.033,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:56:59.849+05:30',
    batteryPercent: 56,
    bearing: 228.595,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.034,
    longitude: 77.032,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:04.844+05:30',
    batteryPercent: 56,
    bearing: 234.171,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.033,
    longitude: 77.032,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:09.874+05:30',
    batteryPercent: 56,
    bearing: 234.171,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.033,
    longitude: 77.031,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:14.913+05:30',
    batteryPercent: 56,
    bearing: 234.172,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.033,
    longitude: 77.031,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:19.908+05:30',
    batteryPercent: 56,
    bearing: 234.172,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.032,
    longitude: 77.03,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:24.917+05:30',
    batteryPercent: 56,
    bearing: 234.172,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.032,
    longitude: 77.03,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:29.883+05:30',
    batteryPercent: 56,
    bearing: 230.353,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.031,
    longitude: 77.029,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:34.918+05:30',
    batteryPercent: 56,
    bearing: 230.353,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.031,
    longitude: 77.029,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:39.88+05:30',
    batteryPercent: 56,
    bearing: 230.353,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.031,
    longitude: 77.028,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:44.886+05:30',
    batteryPercent: 56,
    bearing: 230.353,
    distance: 0
  },
  {
    userId: 11022,
    latitude: 11.03,
    longitude: 77.028,
    speed: 13,
    versioncode: '11',
    receivedtime: '2021-04-24T01:57:49.911+05:30',
    batteryPercent: 56,
    bearing: 234.47,
    distance: 0
  }
];

export const markerData = [
  {
    latitude: 11.032369,
    longitude: 77.030247,
    markerType: 'Idle',
    info: '2021-04-23 23:28:55.129 +0530 IST-2021-04-23 23:30:09.714 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.035234,
    longitude: 77.033938,
    markerType: 'Idle',
    info: '2021-04-23 23:30:14.714 +0530 IST-2021-04-23 23:32:19.729 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.031285,
    longitude: 77.021321,
    markerType: 'Idle',
    info: '2021-04-23 23:32:34.705 +0530 IST-2021-04-23 23:32:39.721 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.082,
    longitude: 77.033,
    markerType: 'Idle',
    info: '2021-04-23 23:36:19.811 +0530 IST-2021-04-23 23:36:24.787 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.082,
    longitude: 77.033,
    markerType: 'Idle',
    info: '2021-04-23 23:42:29.878 +0530 IST-2021-04-23 23:42:34.861 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.082,
    longitude: 77.033,
    markerType: 'Idle',
    info: '2021-04-23 23:45:29.914 +0530 IST-2021-04-23 23:45:34.906 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.082,
    longitude: 77.033,
    markerType: 'Idle',
    info: '2021-04-23 23:47:59.893 +0530 IST-2021-04-23 23:50:54.913 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  },
  {
    latitude: 11.082,
    longitude: 77.033,
    markerType: 'Idle',
    info: '2021-04-23 23:55:14.999 +0530 IST-2021-04-23 23:55:24.936 +0530 IST',
    receivedtime: '0001-01-01T00:00:00Z'
  }
];

// Live
export const RSLLiveUrl = {
  // Map

  newBookingEdit_MapKey: process.env.REACT_APP_NEW_EDIT_BOOKING_API_KEY,
  newBookingOverview_MapKey: process.env.REACT_APP_OVERVIEW_API_KEY,
  manageDriver_MapKey: process.env.REACT_APP_MANAGE_DRIVER_API_KEY,
  manageCar_MapKey: process.env.REACT_APP_MANAGE_CAR_API_KEY,
  others_MapKey: process.env.REACT_APP_OTHERS_API_KEY,

  // Logo
  corpLogo_URL: process.env.REACT_APP_CORP_LOGO,

  // Image
  s3Bucket_URL: process.env.REACT_APP_SBUCKET_URL_LIVE,

  // Login
  commonAdmin_URL: process.env.REACT_APP_COMMON_ADMIN_PANEL,
  commonSwitch_URL: process.env.REACT_APP_COMMON_SWITCH_PANEL,

  // API
  webAPI_URL: 'http://15.184.158.127:3004',
  webLive_URL: 'https://web.limor.us',

  webCorp_URL: 'https://corporateback.royalsmartlimousine.com',

  allowed_Location: 'ae'
};

// Demo
export const RSLDemoUrl = {
  // Map
  newBookingEdit_MapKey: process.env.REACT_APP_NEW_EDIT_BOOKING_API_KEY,
  newBookingOverview_MapKey: process.env.REACT_APP_OVERVIEW_API_KEY,
  manageDriver_MapKey: process.env.REACT_APP_MANAGE_DRIVER_API_KEY,
  manageCar_MapKey: process.env.REACT_APP_MANAGE_CAR_API_KEY,
  others_MapKey: process.env.REACT_APP_OTHERS_API_KEY,

  // Logo
  corpLogo_URL: process.env.REACT_APP_CORP_LOGO,

  // Images
  s3Bucket_URL: process.env.REACT_APP_SBUCKET_URL_DEMO,

  // Login
  // commonAdmin_URL: process.env.REACT_APP_COMMON_ADMIN_PANEL,
  // commonSwitch_URL: process.env.REACT_APP_COMMON_SWITCH_PANEL,
  commonAdmin_URL: 'http://192.168.1.14:3000/login',
  commonSwitch_URL: 'http://192.168.1.14:3000/dashboard/dashboardLogin',

  // API

  webAPI_URL: 'http://34.197.71.192:3008',
  webLive_URL: 'https://webdemo1.limor.us',
  webCorp_URL: 'http://34.197.71.192:3008',

  allowed_Location: ['ae', 'in'],

  localURL: 'http://34.197.71.192:3008'
};
// ⚠️Live and Demo Assingned in same variable name⚠️

    //  export const RSLUrl = RSLDemoUrl; // <--Demo-->
   export const RSLUrl = RSLLiveUrl; // <--Live-->

// <-----#Reference#----->
// const live_url = 'http://15.184.158.127:3004'; // track
// const demo_url = 'http://34.197.71.192:3008'; //demo

// const web_live_url = 'https://web.limor.us';
// const web_demo_url = 'https://webdemo1.limor.us';

// # API KEYS
// REACT_APP_NEW_EDIT_BOOKING_API_KEY=AIzaSyAFa8u5tcZYi8QVz3IhIYxUbjCTpv2bJ_g
// REACT_APP_OVERVIEW_API_KEY=AIzaSyDaR8I_oYm3hUlYy9OcU_5FUTKTGm_n5Vo
// REACT_APP_MANAGE_DRIVER_API_KEY=AIzaSyBUz86iZZFhDI2uyYC8w8OmktsypZkydR4
// REACT_APP_MANAGE_CAR_API_KEY=AIzaSyBUz86iZZFhDI2uyYC8w8OmktsypZkydR4
// REACT_APP_OTHERS_API_KEY=AIzaSyAVFFIumjk9ekJLsEZwKH8wzw08Mc61k0g

// # Img url in manage Corp

// REACT_APP_CORP_LOGO = https://rsllive.s3.me-south-1.amazonaws.com/corporate

// REACT_APP_SBUCKET_URL_DEMO = https://checkbucke.s3.amazonaws.com
// REACT_APP_SBUCKET_URL_LIVE = https://rsllive.s3.me-south-1.amazonaws.com

// # REACT_APP_COMMON_ADMIN_PANEL = http://192.168.1.17:3033/login
// REACT_APP_COMMON_ADMIN_PANEL = https://admin.royalsmartlifestyle.com/login
// # REACT_APP_COMMON_SWITCH_PANEL =  http://192.168.1.17:3033/dashboard/dashboardLogin
// REACT_APP_COMMON_SWITCH_PANEL = https://admin.royalsmartlifestyle.com/dashboard/dashboardLogin
